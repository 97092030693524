/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
/**
 * 参数：[socketOpen | socketClose | socketMessage | socketError] = func，[socket连接成功时触发 | 连接关闭 | 发送消息 | 连接错误]
 * timeout：连接超时时间
 * @type {module.GetWebSocket}
 */

interface GetWebSocket {
  param?: any
  reconnectCount?: number
  socket?: any
  taskRemindInterval?: any
  isSucces?: any
}

class GetWebSocket {
  constructor(param = {}) {
    this.param = param;
    this.reconnectCount = 0;
    this.socket = null;
    this.taskRemindInterval = null;
    this.isSucces=true;
  }

  connection = () => {
    const { socketUrl, timeout = 0 } = this.param;
    
    this.socket = new WebSocket(socketUrl);
    this.socket.onopen = this.onopen;
    this.socket.onmessage = this.onmessage;
    this.socket.onclose = this.onclose;
    this.socket.onerror = this.onerror;
    this.socket.sendMessage = this.sendMessage;
    // this.socket.closeSocket = this.closeSocket;

    // 检测返回的状态码 如果socket.readyState不等于1则连接失败，关闭连接
    if(timeout) {
      const time = setTimeout(() => {
        if(this.socket && this.socket.readyState !== 1) {
            this.socket.close();
        }
        clearInterval(time);
      }, timeout);
    }
  };

  // 连接成功触发
  onopen = () => {
    const { socketOpen } = this.param;
    this.isSucces = false  // 连接成功将标识符改为false
    socketOpen && socketOpen();
  };

  // 向后端发送数据
  sendMessage = (value) => {
    if(this.socket) {
      this.socket.send(JSON.stringify(value));
    }
  };

  // 后端向前端推得数据
  onmessage = (event) => {
    // console.log('后端向前端推得数据====>', event);
    const { socketMessage } = this.param;
    socketMessage && socketMessage(event);
  };
  
  // 关闭连接触发
  onclose = (event) => {
    // console.log('关闭socket收到的数据====>');
    this.isSucces = true   // 关闭将标识符改为true
    
    const { socketClose } = this.param;
    socketClose && socketClose(event);
    
    // 根据后端返回的状态码做操作
    // 我的项目是当前页面打开两个或者以上，就把当前以打开的socket关闭
    // 否则就20秒重连一次，直到重连成功为止 
    // if(event.code=='4500'){
    //   this.socket.close();
    // }else{
    //   this.taskRemindInterval = setInterval(()=>{
    //       if(this.isSucces){
    //           this.connection();
    //       }else{
    //           clearInterval(this.taskRemindInterval)
    //       }
    //   },20000)
    // }
  };

  // 连接报错触发
  onerror = (e) => {
    const { socketError } = this.param;
    this.socket = null;
    socketError && socketError(e);
  };
}

export {
  GetWebSocket,
}