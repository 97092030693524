/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-console */
/* eslint-disable prefer-template */
import axiosHelper from 'utils/request'
import { TTBASE_URL } from 'config/constants/api'
import { webSocketSubscribe } from '../../../utils/webSocket'


let pairTokenAddress = {
  pairAddress: String,
  tradeCoinSymbol: Symbol,
}

export const intervals = {
	'1': '1min',
	'5': '5min',
	'15': '15min',
	'30': '30min',
	'60': '1hour',
	'1D': '1day',
	'1W': '1week',
}


/**
 * 首页 - 查询出热门币种
 * @param {*String} 
 */
export async function getHotSymbolList(chainId, swipperActive) {
  const url = `${TTBASE_URL}/market/coin/allList/` + chainId + '/' + swipperActive
  const res: any = await axiosHelper.get(url)

  if(res.code === 200){
    const { data } = res
    return data
  }

  return null
}


/**
 * 根据token Address获取pair Address、token Symbol
 * @param {*String} 
 */
export async function getFetchSymbols(chainId, currencyAddress) {
  const url = `${TTBASE_URL}/market/pair/address/` + chainId + `/` + currencyAddress
  const res: any = await axiosHelper.get(url)

  if(res.code === 200){
    const { data } = res
    pairTokenAddress = data

    return data
  }

  return null
}


/**
 * 根据token Address对应的pair Address，查询出K线数据
 * @param {*String} resolution K线周期
 */
export async function getKLines(resolution: any) {
  const { pairAddress } = pairTokenAddress

  let chainId = 56
  const eth_chainId = await window.ethereum.request({ method: 'eth_chainId' });
  if (eth_chainId === '0x533') {
    chainId = 1331
  } else {
    chainId = 56
  }

  if(pairAddress){
    const reson = intervals[resolution]

    // 请求k线数据
    const kLinesUrl = `${TTBASE_URL}/market/trade/klineList?chainId=` + chainId + `&pairAddress=` + pairAddress + `&period=` + reson
    const res: any = await axiosHelper.get(kLinesUrl)

    if(res.code === 200){
      return res
    }
  }

  return null
}


/**
 * 根据token Address对应的pair Address，查询出交易历史
 * @param {*String} 
 */
export async function getKList() {
  const { pairAddress } = pairTokenAddress

  let chainId = 56
  const eth_chainId = await window.ethereum.request({ method: 'eth_chainId' });
  if (eth_chainId === '0x533') {
    chainId = 1331
  } else {
    chainId = 56
  }

  const url = `${TTBASE_URL}/market/trade/allList?chainId=` + chainId + `&pairAddress=` + pairAddress + `&size=100`
  const res: any = await axiosHelper.get(url)
  
  if(res.code === 200){
    const { data } = res
    return data
  }

  return null
}


/**
 * 根据token Address对应的pair Address，查询K线头部数据
 * @param {*String} 
 */
export async function getKCoin() {
  const { pairAddress } = pairTokenAddress

  let chainId = 56
  const eth_chainId = await window.ethereum.request({ method: 'eth_chainId' });
  if (eth_chainId === '0x533') {
    chainId = 1331
  } else {
    chainId = 56
  }

  const url = `${TTBASE_URL}/market/trade/24Hkline?chainId=` + chainId + `&pairAddress=` + pairAddress
  const res: any = await axiosHelper.get(url)

  if(res.code === 200){
    const { data } = res
    return data
  }

  return null
}



/**
 * 订阅K线数据
 * @param {*String} resolution K线周期
 */
export const subscribeKline = async ({ resolution, subscriberUID }, callback) => {
  const { pairAddress } = pairTokenAddress

  let chainName = "bsc"
  const eth_chainId = await window.ethereum.request({ method: 'eth_chainId' });
  if (eth_chainId === '0x533') {
    chainName = "cc"
  } else {
    chainName = "bsc"
  }

  // 订阅K线参数
  const reson = intervals[resolution]
  const kLineParams = {
    "method": "subscribe",
    "params": [chainName, pairAddress, reson]
  }

  webSocketSubscribe(kLineParams, '')

  if(window) { 
    setInterval(() => {
      if(window && window.GkTicker && window.GkTicker.event === "kline"){
        callback(formatingKline(window && window.GkTicker))
      }
    }, 1000)
  }
}


function formatingKline(msg) {
  // console.log('返回订阅K线数据====>', msg.data)
  const data = msg.data
  return {
    time: Number(data.timePeriod),
    close: Number(data.closePrice),
    open: Number(data.openPrice),
    high: Number(data.highestPrice),
    low: Number(data.lowestPrice),
    volume: Number(data.volume),
	}
}


/**
 * 订阅热门币种数据
 * @param {*String} chainName
 * 
 */
export const subscribeWeb = ({ chainName, param1, param2 }, callback) => {
  const { pairAddress } = pairTokenAddress

  // 订阅参数
  if(!param2){
    const params = {
      "method": "subscribe",
      "params": [chainName, param1]
    } 
    webSocketSubscribe(params, '')
  }else{
    const params = {
      "method": "subscribe",
      "params": [chainName, param1, param2]
    } 
    const params2 = {
      "method": "subscribe",
      "params": [chainName, param1]
    } 
    webSocketSubscribe(params, params2)
  }

  if(window) { 
    setInterval(() => {
      if(window && window.GkTicker){
        callback(window && window.GkTicker)
      }
    }, 300)
  }
}