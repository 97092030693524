/* eslint-disable no-console */
/* eslint-disable prefer-template */
/* eslint-disable no-param-reassign */
/* eslint-disable no-var */
/* eslint-disable no-else-return */
export const parseNumber = (num: any, decimal?: number) => {
  let newNum = "";
  let count = 0;
  var numStr = String(num);  // 数字转为字符串;
  // 当字符串不含有小数点
  if (numStr.indexOf(".") == -1) {
    let i;
    for (i = numStr.length - 1; i >= 0; i--) {
        if (count % 3 == 0 && count != 0) {
            newNum = numStr.charAt(i) + "," + newNum ;
        } else {
            newNum = numStr.charAt(i) + newNum ;
        }
        count++;
    }
    // numStr= newNum + ".00"; // 自动补小数点后两位
    numStr= newNum

    return numStr;
  } else {
    // 当字符串含有小数点
    for (let i = numStr.indexOf(".") - 1; i >= 0; i--) {
        if (count % 3 == 0 && count != 0) {
            newNum = numStr.charAt(i) + "," + newNum ;
        } else {
            newNum = numStr.charAt(i) + newNum ; // 字符拼接
        }
        count++;
    }

    const reg = /^0+/;
    const numberArr = String(numStr).split('.');
    
    //  小数点后面 0 的个数
    const zeroArrCount = numberArr && numberArr[1]?.match(reg)
    const zeroCount = zeroArrCount?.[0]
    const zeroCountLen = zeroCount?.length || 0
    // 保留几位小数
    const zeroDecimal = zeroCountLen !== 0 ? zeroCountLen+2 : 0

    const numDecimal = decimal ? decimal+1 : 3+1
    const decimalNewNum = zeroDecimal === 0 ? numDecimal : (zeroDecimal+1)

    numStr = newNum + (numStr+ "00").substr((numStr+ "00").indexOf("."), decimalNewNum);

    return numStr;
  }
}


// 数字转换为万单位
export const formatNumber = (num: any) => {
  const numNum = Number(num);
	if (numNum == 0 || (numNum > 0 && numNum < 10000)) {
		return parseNumber(num) 
	} else {
		return parseNumber(String(numNum / 10000), 2) + '万';
	}
}
