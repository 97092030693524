/* eslint-disable no-console */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-else-return */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable prefer-const */
import { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import { Button, Flex, Heading, Text, useModal, useToast } from '@pancakeswap/uikit'
import { useAccount } from 'wagmi'
import { TTBASE_URL } from 'config/constants/api'
import axiosHelper from 'utils/request'
import useTheme from 'hooks/useTheme'
import { useTranslation } from '@pancakeswap/localization'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { Tabs, Swiper } from 'antd-mobile'
import { SwiperRef } from 'antd-mobile/es/components/swiper'
import addresses from 'config/constants/contracts'
import { Token } from '@pancakeswap/sdk'
import { parseNumber } from 'utils/parseNumber'
import { getHotSymbolList, subscribeWeb } from '../Token/api'
import { GetWebSocket } from '../../utils/socket'

const HomeStyled = styled.div`
  font-family: Noto Sans S Chinese;
  font-weight: 500;
  max-width: 1080px;
  margin: auto;
  .coinNavView {
    margin-top: 27px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  .coinNavItem {
    padding: 0 10px;
    height: 24px;
    line-height: 24px;
    margin-right: 5px;
  }
  .coinNavItemActive {
    text-align: center;
    background: #ecebef;
    border-radius: 3px;
    font-size: 15px;
    font-family: Noto Sans S Chinese;
    font-weight: 500;
    color: #27262b;
  }
  .topCoinRowLeft {
    align-items: center !important;
  }
  .coinTableView {
    font-family: Noto Sans S Chinese;
    margin-bottom: 49px;
    width: 100%;
    .topCoinPriceRow {
      margin-bottom: 16px;
    }
    .coinPriceRow {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 62px;
      .coinRowLeft {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;
        flex: 3;
        .topToken1 {
          font-size: 13px;
          margin-right: 6px;
        }
        .topTokenImg {
          width: 9px;
          height: 6px;
        }
        .token1 {
          font-size: 16px;
          font-family: Noto Sans S Chinese;
          font-weight: 500;
          color: #1f262e;
          opacity: 1;
        }
        .token2 {
          font-size: 12px;
          font-weight: 500;
          font-family: Noto Sans S Chinese;
          color: #8a8f95;
          opacity: 1;
          margin: 0 3px 0 1.5px;
        }
        .leverage {
          font-size: 12px;
          color: #ee9f0e;
          opacity: 1;
          width: 16px;
          height: 16px;
          line-height: 16px;
          text-align: center;
          background: #f2f2f2;
          opacity: 1;
          border-radius: 0px;
        }
      }
      .topPrice {
        flex: 1;
        font-size: 12px;
        font-weight: 400;
        color: #8a8f95;
        opacity: 1;
        text-align: right;
      }

      .latestPrice {
        flex: 1;
        font-size: 16px;
        font-family: Noto Sans S Chinese;
        font-weight: 500;
        color: #1f262e;
        text-align: right;
        .price {
          margin-bottom: 9px;
        }
        .price2 {
          font-size: 12px;
          font-family: Noto Sans S Chinese;
          font-weight: 400;
          color: #8a8f95;
        }
      }
      .topChange {
        flex: 1;
        max-width: 100px;
        padding: 0 15px 0 25px;
        font-size: 12px;
        font-weight: 400;
        color: #8a8f95;
        opacity: 1;
        text-align: right;
        margin-left: 21px;
      }
      .change {
        flex: 1;
        max-width: 100px;
        height: 36px;
        padding: 0 25px;
        line-height: 36px;
        text-align: center;
        background: #2ebd87;
        border-radius: 3px;
        font-size: 14px;
        color: #ffffff;
        margin-left: 21px;
      }
      .changeRed {
        background: #f8465e;
      }
    }
  }
`
const TabItemCttStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
`


const HomeTable = ({ status, coinsPriceFav, hotCoinsList }) => {
  const { t } = useTranslation()
  
  const symbolList = status === 0 ? coinsPriceFav : hotCoinsList
  
  return (
    <>
      <div className="coinTableView">
        <div className="coinPriceRow topCoinPriceRow" style={{ height: 'unset' }}>
          <div className="coinRowLeft topCoinRowLeft">
            <div className="topToken1">{t('Name')}</div>
          </div>
          <div className="topPrice">{t('Last Price')}</div>
          <div className="topChange">{t('24h chg%')}</div>
        </div>
        {symbolList &&
          symbolList.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <a href={`/token/${item.tradeCoinAddress}`}  className="coinPriceRow" key={index}>
              <div className="coinRowLeft">
                <div className="token1">{item.tradeCoinSymbol}</div>
                <div className="token2">/USDT</div>
              </div>
              <div className="latestPrice">
                <div>${parseNumber(item.price, 2)}</div>
              </div>
              <div className={Number(item.priceChange) >= 0 ? 'change' : 'change changeRed'}>
                {
                  Number(item.priceChange) >= 0 ?
                  <span>+</span>
                  :
                  null
                }
                {(item.priceChange).toFixed(2)}%
              </div>
            </a>
          ))}
      </div>
    </>
  )
}

const HomeLine: React.FC<React.PropsWithChildren> = () => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  const { address: account } = useAccount()
  const { chainId } = useActiveChainId()

  const swiperRef = useRef<SwiperRef>(null)
  const [swipperActive, setswipperActive] = useState(1)
  const [coinsPriceFav, setCoinsPriceFav] = useState([])
  const [hotCoinsList, setHotCoinsList] = useState([])
  const [dataSocket, setDataSocket] = useState()

  useEffect(()=>{
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[chainId])

  const init = async () => {
    // 查询出币种信息
    const res = await getHotSymbolList(chainId, swipperActive);
    if (!res) {
      return
    }

    setHotCoinsList(res)
  }

  // 自选、热门
  const [coinNavList, setCoinNavList] = useState([
    {
      title: t('Favorites'),
    },
    {
      title: t('Hot'),
    },
  ])

  
  useEffect(() => {
    if(hotCoinsList.length === 0){
      return
    }

    // 订阅热门币种参数
    let chainName = "bsc"
    if (chainId === 1331) {
      chainName = "cc"
    } else {
      chainName = "bsc"
    }

    const param1 = "hot"
    const param2 = ""
    subscribeWeb({ chainName, param1, param2 }, callback => {
      const socketData = callback
      if(socketData.event === "hot"){
        setDataSocket(socketData.data)
      }
      // 更新完成后清空上次的数据
      // window.GkTicker = [];
    })
    
  }, [hotCoinsList.length])


  useEffect(() => {
    if(!dataSocket){
      return
    }

    // 根据webSocket返回数据，更新币种信息
    webSocketSymbolData(dataSocket)
  }, [dataSocket])


  const webSocketSymbolData = (data) => {
    const arr = [...hotCoinsList]
    hotCoinsList.map((item, index) => {
      if(item.tradeCoinAddress == data.tradeCoinAddress){
        arr.splice(index, 1, data)
        setHotCoinsList(arr)
      }
      return arr
    })
  }


  return (
    <>
      <HomeStyled>
        <div>
          <Tabs
            activeKey={coinNavList[swipperActive].title}
            className="coinNavView"
            onChange={(key) => {
              const index = coinNavList.findIndex((item) => item.title === key)
              setswipperActive(index)
              swiperRef.current?.swipeTo(index)
            }}
          >
            {coinNavList.map((item, index) => (
              <Tabs.Tab
                // title={item.title}
                title={index===0 ? t('Favorites') : t('Hot')}
                key={item.title}
                className={swipperActive === index ? 'coinNavItem coinNavItemActive' : 'coinNavItem'}
              />
            ))}
          </Tabs>
          <Swiper
            direction="horizontal"
            loop
            indicator={() => null}
            ref={swiperRef}
            defaultIndex={swipperActive}
            onIndexChange={(index) => {
              setswipperActive(index)
            }}
          >
            {coinNavList &&
              coinNavList.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Swiper.Item key={index}>
                  <TabItemCttStyled>
                    <HomeTable status={index} coinsPriceFav={coinsPriceFav} hotCoinsList={hotCoinsList} />
                  </TabItemCttStyled>
                </Swiper.Item>
              ))}
          </Swiper>
        </div>
      </HomeStyled>
    </>
  )
}

export default HomeLine
