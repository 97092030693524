/* eslint-disable no-console */
/* eslint-disable prefer-const */
import { useState, useEffect } from 'react'
import { TTWS_URL } from 'config/constants/api'
import { GetWebSocket } from './socket'

const wsUrl = `${TTWS_URL}`

export const webSocketSubscribe = ( params, params2 ) => {
  let SocketGet;
  SocketGet = new GetWebSocket({
    socketUrl: wsUrl,
    timeout: 5000,  // 连接超时时间
    // 当网络连接建立时触发该事件
    socketOpen: () => {
      // console.log('连接建立成功1111===>');
      let timeoutObj;
      timeoutObj = setTimeout(() => {
        // 建立连接，发送参数
        SocketGet.sendMessage(params)
        if(params2){
          SocketGet.sendMessage(params2)
        }
      }, 1000);
    },
    // 服务器向前端推送的数据
    socketMessage: (event) => {
      // console.log('服务器向前端推送的数据1111111====>', event)
      try {
        if (typeof JSON.parse(event.data) == "object") {
          window.GkTicker = JSON.parse(event.data)
        }else if(event.data === '1'){
          // console.log('推送数据catch11===>', event)
        }
      } catch(e) {
        // console.log('推送数据catch22===>', event.data)
      }
    },
    // 断开连接触发
    socketClose: (event) => {
      // console.log('断开连接00000====>', event);
    },
    // 连接报错触发
    socketError: (event) => {
        // console.log('连接报错00000====>', event);
    },
  })

  // 创建socket连接
  try {
    SocketGet.connection();
  } catch (e) {
    // 捕获异常，防止js error
  }

  return () => {
    SocketGet.onclose()
  }

}