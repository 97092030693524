/* eslint-disable @next/next/no-html-link-for-pages */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-else-return */
/* eslint-disable react/jsx-no-target-blank */
import { useRef, useState } from 'react'
import styled from 'styled-components'
import { Button, Flex, Heading, Text, useModal, useToast } from '@pancakeswap/uikit'
import { useAccount } from 'wagmi'
import useTheme from 'hooks/useTheme'
import { useTranslation } from '@pancakeswap/localization'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { Tabs, Swiper } from 'antd-mobile'
import { SwiperRef } from 'antd-mobile/es/components/swiper'
import addresses from 'config/constants/contracts'
import { UsdtTokenInfo } from 'config'
import { Token } from '@pancakeswap/sdk'
import { useCurrencyBalance } from 'state/wallet/hooks'
import { useRouter } from 'next/router'
import ActiveNodeModal from './activeNodeModal'
import MultipleBanner from './components/Banners/MultipleBanner'
import HomeLine from './homeLine'

const HomeBannerStyled = styled.div`
  width: 100%;
  margin: auto;
  margin-top: 0px;
  ${({ theme }) => theme.mediaQueries.md} {
    max-width: 1080px;
    margin: auto;
    margin-top: 42px;
  }
`
const HomeStyled = styled.div`
  padding: 0 15px;
  font-family: Noto Sans S Chinese;
  font-weight: 500;
  max-width: 1080px;
  margin: auto;
  .adm-tabs-tab-active {
    color: unset;
  }
  .adm-tabs-tab-line {
    background: unset;
  }
  .adm-tabs-header {
    border-bottom: unset;
  }
  .totalAssetsView {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 35px;
    .taLeftView {
      .assetsLabel {
        height: 14px;
        font-size: 13px;
        line-height: 14px;
        color: #27262b;
        opacity: 1;
      }
      .assetsMoneyView {
        margin: 7px 0 14px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .assetsMoney {
          height: 30px;
          font-size: 25px;
          font-family: Roboto;
          font-weight: bold;
          line-height: 30px;
          color: #1f262e;
          opacity: 1;
          margin-right: 5px;
        }
        span img {
          width: 22px;
          height: 13px;
          cursor: pointer;
          &:hover {
            opacity: 0.65;
          }
        }
      }
      .aboutMoney {
        height: 16px;
        font-size: 13px;
        font-family: Roboto;
        font-weight: 500;
        line-height: 16px;
        color: #8a8f95;
        opacity: 1;
      }
    }
    .taRightView {
      .depositBtn {
        width: 94px;
        height: 34px;
        line-height: 34px;
        background: #ffc247;
        opacity: 1;
        border-radius: 4px;
        text-align: center;
        font-size: 14px;
        color: #1f262e;
        opacity: 1;
        cursor: pointer;
        &:hover {
          opacity: 0.65;
        }
      }
    }
  }
  .functionView {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 18px;
    border-bottom: 1px solid #F6F6F6;
    .functionItemView {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      flex: 0 0 25%;
      ${({ theme }) => theme.mediaQueries.sm} {
        flex: 0 0 25%;
      }
      > img {
        width: 48px;
        height: 48px;
        background: #f6f6f6;
        border-radius: 50%;
        opacity: 1;
        margin-bottom: 13px;
      }
      .functionTitle {
        font-size: 12px;
        color: #27262b;
        opacity: 1;
        font-weight: 400;
        margin-bottom: 20px;
        text-align: center;
      }
    }
  }
  .quickBuyView {
    height: 82px;
    background: radial-gradient(circle, #ffffff 0%, #f5f5f5 100%);
    opacity: 1;
    border-radius: 0px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 0 13px;
    align-items: center;
    border-radius: 5px;
    .quickLeft {
      width: 80%;
    }
    .textQuick {
      font-size: 15px;
      font-weight: 900;
      font-family: Noto Sans S Chinese;
      color: #27262b;
      opacity: 1;
      margin-bottom: 9px;
    }
    .textMoreChose {
      font-size: 10px;
      font-weight: 400;
      color: #8a8f95;
      opacity: 1;
    }
    .quickRight {
      > img {
        width: 45px;
        height: 47px;
      }
    }
  }
  .coinNavView {
    margin-top: 27px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  .coinNavItem {
    padding: 0 10px;
    height: 24px;
    line-height: 24px;
    margin-right: 5px;
  }
  .adm-tabs-tab {
    padding: 0;
    font-size: 15px;
    font-family: Noto Sans S Chinese;
    font-weight: 500;
    color: #27262b;
  }
  .adm-tabs-tab-active {
    font-size: 15px;
    font-family: Noto Sans S Chinese;
    font-weight: 500;
    color: #27262b;
  }
  .coinNavItemActive {
    text-align: center;
    background: #ecebef;
    border-radius: 3px;

    font-size: 15px;
    font-family: Noto Sans S Chinese;
    font-weight: 500;
    color: #27262b;
  }
  .topCoinRowLeft {
    align-items: center !important;
  }
  .coinTableView {
    font-family: Noto Sans S Chinese;
    margin-bottom: 49px;
    width: 100%;
    .topCoinPriceRow {
      margin-bottom: 16px;
    }
    .coinPriceRow {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 62px;
      .coinRowLeft {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;
        flex: 3;
        .topToken1 {
          font-size: 13px;
          margin-right: 6px;
        }
        .topTokenImg {
          width: 9px;
          height: 6px;
        }
        .token1 {
          font-size: 16px;
          font-family: Noto Sans S Chinese;
          font-weight: 500;
          color: #1f262e;
          opacity: 1;
        }
        .token2 {
          font-size: 12px;
          font-weight: 500;
          font-family: Noto Sans S Chinese;
          color: #8a8f95;
          opacity: 1;
          margin: 0 3px 0 1.5px;
        }
        .leverage {
          font-size: 12px;
          color: #ee9f0e;
          opacity: 1;
          width: 16px;
          height: 16px;
          line-height: 16px;
          text-align: center;
          background: #f2f2f2;
          opacity: 1;
          border-radius: 0px;
        }
      }
      .topPrice {
        flex: 1;
        font-size: 12px;
        font-weight: 400;
        color: #8a8f95;
        opacity: 1;
        text-align: right;
      }

      .latestPrice {
        flex: 1;
        font-size: 16px;
        font-family: Noto Sans S Chinese;
        font-weight: 500;
        color: #1f262e;
        text-align: right;
        .price {
          margin-bottom: 9px;
        }
        .price2 {
          font-size: 12px;
          font-family: Noto Sans S Chinese;
          font-weight: 400;
          color: #8a8f95;
        }
      }
    }
  }
`
const TabItemCttStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
`


interface ifunctionItem {
  img: string
  title: string
  href: string
}


const Home: React.FC<React.PropsWithChildren> = () => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  const { address: account } = useAccount()
  const { chainId } = useActiveChainId()
  // const symbol = UsdtTokenInfo.symbol
  // const symbolCurrency = new Token(chainId, addresses.usdt[chainId], UsdtTokenInfo.decimal, symbol)
  // const symbolCurrencyBalance = useCurrencyBalance(account ?? undefined, symbolCurrency || undefined)
  
  const [functions, setFunctions] = useState([
    {
      img: '/images/dswap/homeFunc1.png',
      title: '算力矿池',
      href: '/power',
    },
    {
      img: '/images/dswap/homeFunc2.png',
      title: '激活节点',
      href: '/active',
    },
    {
      img: '/images/dswap/homeFunc3.png',
      title: '节点分红',
      href: '/dividend',
    },
    {
      img: '/images/dswap/homeFunc5.png',
      title: '更多',
      href: '#',
    },
  ])
  // 自选、热门、新币上线、涨幅榜、跌幅榜
  const [coinNavList, setCoinNavList] = useState([
    {
      title: t('Favorites'),
    },
    {
      title: t('Hot'),
    },
    // {
    //   title: t('New Currency'),
    // },
    // {
    //   title: t('Gainers'),
    // },
    // {
    //   title: t('Losers'),
    // },
  ])

  const [activeNodeModal] = useModal(<ActiveNodeModal />)
  const { push } = useRouter()
  function nodeDividendClick() {
    push('/dividend')
  }

  return (
    <>
      <HomeBannerStyled>
        <MultipleBanner />
      </HomeBannerStyled>
      <HomeStyled>
        <div className="functionView">
          {/* {functions &&
            functions.map((item: ifunctionItem, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <a className="functionItemView" href={item.href} key={index}>
                <img src={item.img} alt="" />
                <div className="functionTitle">{item.title}</div>
              </a>
            ))} */}
            {/* <a className="functionItemView" href="/power">
              <img src="/images/dswap/homeFunc1.png" alt="" />
              <div className="functionTitle">{t('Power Pool')}</div>
            </a> */}
            {/* <a className="functionItemView" href="/active">
              <img src="/images/dswap/homeFunc2.png" alt="" />
              <div className="functionTitle">{t('Active Node')}</div>
            </a> */}
            <a className="functionItemView" href="/dogepool">
              <img src="/images/dswap/homeFunc2.png" alt="" />
              <div className="functionTitle">{t('DOEG Pool')}</div>
            </a>
            <a className="functionItemView" href="/dividend">
              <img src="/images/dswap/homeFunc3.png" alt="" />
              <div className="functionTitle">{t('Node Dividend')}</div>
            </a>
            <a className="functionItemView" href="/currency">
              <img src="/images/dswap/homeFunc11.png" alt="" />
              <div className="functionTitle">{t('Trading to earn currency')}</div>
            </a>
            <a className="functionItemView" href="http://creda.app/home" target="_blank">
              <img src="/images/dswap/homeFunc12.png" alt="" />
              <div className="functionTitle">{t('Credit evaluation')}</div>
            </a>
        </div>
        {/* <div className="quickBuyView">
          <div className="quickLeft">
            <div className="textQuick">{t('P2P Trading')}</div>
            <div className="textMoreChose">{t('Bank Transfer,Digital Wallet Transfer,Mobile Payment and more')}</div>
          </div>
          <div className="quickRight">
            <img src="/images/kuaijiemaibi.png" alt="" />
          </div>
        </div> */}
        
        {/* 行情 */}
        <HomeLine />
        
      </HomeStyled>
    </>
  )
}

export default Home
