/* eslint-disable no-console */
import { useState, useCallback, useMemo, useEffect } from 'react'
import {
  Text,
  Button,
  ButtonMenu,
  ButtonMenuItem,
  CloseIcon,
  Heading,
  IconButton,
  InjectedModalProps,
  ModalBody,
  ModalContainer,
  ModalHeader as UIKitModalHeader,
  ModalTitle,
  ModalProps,
  Flex,
  useToast,
  Input,
} from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import styled from 'styled-components'
import { useWeb3React } from '@pancakeswap/wagmi'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { CommitButton } from 'components/CommitButton'
import { AutoColumn } from 'components/Layout/Column'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { RowBetween } from 'components/Layout/Row'
import useTokenAllowance from 'hooks/useTokenAllowance'
import { Token, CurrencyAmount, Currency } from '@pancakeswap/sdk'
import tryParseAmount from '@pancakeswap/utils/tryParseAmount'
import Dots from 'components/Loader/Dots'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import { ROUTER_ADDRESS } from 'config/constants/exchange'
import { useActiveNodeContract, useERC20, useTokenContract, useZnbStakingContract } from 'hooks/useContract'
import addresses from 'config/constants/contracts'
import { BuyTokenInfo } from 'config'
import BigNumberjs from 'bignumber.js'

const ModalHeader = styled(UIKitModalHeader)`
  background: ${({ theme }) => theme.colors.gradientBubblegum};
`

const ActiveNodeModal: React.FC<React.PropsWithChildren<InjectedModalProps>> = ({ onDismiss }) => {
  const [shouldShowApprovalGroup, setShouldShowApprovalGroup] = useState(false)
  const { t } = useTranslation()
  const { chainId } = useActiveWeb3React()
  const znbStakingContract = useZnbStakingContract()
  const { account } = useWeb3React()

  const { toastError } = useToast()
  const activeNodeContract = useActiveNodeContract()
  const payAddress = addresses.bunnyUSDT[chainId]
  const payToken = useMemo(() => {
    return new Token(chainId, payAddress, 18, 'USDT')
  }, [payAddress, chainId])

  const spender = addresses.activeNode[chainId]
  const independentAmount: CurrencyAmount<Currency> | undefined = useMemo(() => {
    const amount = new BigNumberjs(3000).shiftedBy(18).toString()
    console.log('burnBuyAmount', amount)
    return tryParseAmount(amount, payToken)
  }, [payToken])
  const [approvalA, approveACallback] = useApproveCallback(independentAmount, spender)
  console.log('approvalUsdt', approvalA)

  const handleActiveNode = () => {
    activeNodeContract
      .safeMint(account)
      .then()
      .catch((error) => {
        console.error(error)
        toastError(t('Error'), error?.reason || error?.message)
      })
  }

  return (
    <ModalContainer $minWidth="375px">
      <ModalHeader>
        <ModalTitle>
          <Heading>{t('Active Node')}</Heading>
        </ModalTitle>
        <IconButton variant="text" onClick={onDismiss}>
          <CloseIcon width="24px" color="text" />
        </IconButton>
      </ModalHeader>
      <ModalBody maxWidth="375px" width="100%">
        <Flex padding="20px 20px 0" flexDirection="column">
          <Text mb="10px" ml="8pxs" fontSize="12px" color="textSubtle">
            {t(
              'Activate the trading node, get the Bunny badge, enjoy the unlimited commission of the whole network transaction fee, feel at ease for your own use, share and earn coins, activation needs to pay 5000 USDT',
            )}
          </Text>
          {approvalA !== ApprovalState.APPROVED && (
            <Button  mt="30px" onClick={approveACallback} width="100%">
              {approvalA === ApprovalState.PENDING ? (
                <Dots>{t('Enabling %asset%', { asset: payToken?.symbol })}</Dots>
              ) : (
                t('Enable %asset%', { asset: payToken?.symbol })
              )}
            </Button>
          )}
          <Button
            mt="20px"
            mb="30px"
            disabled={approvalA !== ApprovalState.APPROVED}
            minWidth="100%" // Bypass the width="fit-content" on Links
            onClick={handleActiveNode}
          >
            {/* 重名Active */}
            {t('Active Node')}
          </Button>
        </Flex>
      </ModalBody>
    </ModalContainer>
  )
}

export default ActiveNodeModal
